import React, { Component } from 'react';
import uuid from 'uuid';
export default  class Experience extends Component {
  render() {
    let resumeData = this.props.resumeData;

    return (
      <section id="experience">
        <div className="row work" id="experience">
            <div className="three columns header-col">
               <h1><span>Experience</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.work && resumeData.work.map((item) => {
                  return(
                    <div className="row item" key={item.id}>
                       <div className="twelve columns">
                          <span className="title"><img alt="logo" className="logo" src={item.icon}/>{item.CompanyName}</span>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span> <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em></p>
                          <div className="ExpDetail">
                          { item.Achievements && item.Achievements.map((achievement) => {
                             return (
                              <div className="achievement" key={uuid()}>{achievement} </div>
                             )
                           })
                          }
                          <div className="tech">{item.tech}</div>
                          </div>
                         
                       </div>

                    </div>

                  )
                })
              }
            </div> 
         </div>

      </section>
    );
  }
}