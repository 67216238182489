import React, { Component } from 'react';
export default  class Competences extends Component {
  render() {
    let resumeData = this.props.resumeData;

    return (
      <section id="competences">

         <div className="row skill">

            <div className="three columns header-col">
               <h1><span>Compétences</span></h1>
            </div>

            <div className="nine columns main-col">

               <p>
               {resumeData.skillsDescription}
               </p>

   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item) => {
                    return(
                      <li key={item.skillname}>
                      <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                      </span><em>{item.skillname}</em>
                      </li>
                    )
                  })
                }

   					</ul>

   				</div>

   			</div>

         </div>

      </section>
    );
  }
}