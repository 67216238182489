import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row">

              <div className="contact-details">

              <h1> </h1>
              <h2>Contact</h2>
              <p className="address">
                  <span>{resumeData.name}</span>
              </p>
              <p className="detail">
                  <span>{resumeData.address}</span>
                  <br/>
                  <span>LinkedIn Id: {resumeData.linkedinId}</span>
                  <br></br>
                  <span>Website : <a href={resumeData.website} target="_new">{resumeData.website}</a></span>
                </p>
              </div>
            </div>
        </section>
        );
  }
}