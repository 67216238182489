import React, { Component, Fragment } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    // let aboutMe = <Fragment >{resumeData.aboutme}</Fragment>
    const listItems = resumeData.aboutme.map((item) =>
        <Fragment key={item.id}>{item.name}<br/></Fragment>
    );
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />

            </div>

            <div className="six columns main-col">

               <h2>A propos</h2>
               <p>
               {
                 listItems
               }
               </p>
            </div>

            <div className="three columns">

               <img className="profile-pic"  src="images/wm-logo.png" alt="" />

            </div>
         </div>
      </section>
    );
  }
}