import React, { Component } from 'react';
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    let idx = 0;
    // let href = '#modal-0';
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>CERTIFICATIONS / FORMATION SUIVIES</h1>
          <div id="portfolio-wrapper" className="bgrid-thirds s-bgrid-thirds cf">
          {
           
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              idx += 1;
              return(
                <div className="columns portfolio-item" key={idx}>
                  <div className="item-wrap">
                    <a className="item-popup" href={`${item.imgurl}`}>
                      <img src={`${item.imgurl}`} className="item-img" alt={item.name}/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
        );
  }
}